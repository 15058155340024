<template>
  <div>
    <page-loading v-if="getCustomerListIsLoading"></page-loading>
    <HeaderCard v-else :user="getCustomer"></HeaderCard>
    <export-excell
      :checkedList="checkedList"
      :selectedAllItemWithoutPage="selectedAllItemWithoutPage"
      :fields="fields"
      :count="count"
      :name="`${checkedList.length} ${$t('users')}: ${$t('quizzes')}`"
      @getAll="GET_ALL"
    ></export-excell>
    <Table
      checkable
      :check-value="checkedList"
      @change:selectAllWithoutPage="SET_SELECT_ALL_WITHOUT_PAGE"
      @change:checkedList="(e) => (checkedList = e)"
      :hideHeader="inside"
      :count="count"
      :headers="headers"
      :search-placeholder="$t('searchQuiz')"
      @change:search="SET_SEARCH"
      :loading="isLoading"
      :items="items"
      no-card
    >
      <template #fullname="{ item }">
        <router-link :to="`/user/${$route.params.id}`">
          <div>{{ item.fullname }}</div>
          <div>
            <small class="text-muted">
              <div>{{ $t("lastAction") }}: {{ formatter(item.createdAt) }}</div>
            </small>
          </div>
        </router-link>
      </template>
      <template #videoDescription="{ item }">
        <div>{{ item.videoDescription || $t("notEntered") }}</div>
        <div>
          <small class="text-muted">
            {{ item.sectionName || $t("notEntered") }} >
            {{ item.productTitle || $t("notEntered") }}
          </small>
        </div>
      </template>
    </Table>
    <div v-if="!inside" class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
  </div>
</template>

<script>
import Table from "../../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import { Currency } from "gurhan/helpers/Currency";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import HeaderCard from "../../User/HeaderCard.vue";
import PageLoading from "../../Shared/PageLoading.vue";
import ExportExcell from "../ExportExcell.vue";

export default {
  components: {
    ExportExcell,
    PageLoading,
    BoostrapPagination,
    Table,
    HeaderCard,
  },
  props: {
    inside: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 10,
    },
  },
  data() {
    return {
      checkedList: [],
      selectedAllItemWithoutPage: false,
      fields: {},
      items: [],
      search: "",
      isLoading: true,
      count: 0,
      page: 1,
      headers: [
        {
          title: this.$t("user"),
          value: "fullname",
          col: 12,
          md: 4,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("quizName"),
          value: "quizName",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("videoTitle"),
          value: "videoDescription",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("productName"),
          value: "productTitle",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("quizCount"),
          value: "count",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
      ],
    };
  },
  computed: {
    getCustomerListIsLoading() {
      return this.$store.state.CustomerList.customerListIsLoading;
    },
    getCustomer() {
      const customerList = this.$store.state.CustomerList.customerList.find(
        (p) => p.id === Number(this.$route.params.id)
      );
      if (customerList) return customerList;
      else return { id: -1 };
    },
    pages() {
      return Math.ceil(this.count / 10);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    formatter,
    Currency,
    SET_SELECT_ALL_WITHOUT_PAGE(all) {
      this.selectedAllItemWithoutPage = all;
    },
    CREATE_EXCEL_FIELD() {
      this.fields = {
        [`${this.$t("name")}`]: {
          field: "fullname",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("quizName")}`]: {
          field: "quizName",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("videoTitle")}`]: {
          field: "videoDescription",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("productName")}`]: {
          field: "productTitle",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("quizCount")}`]: {
          field: "count",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("lastAction")}`]: {
          field: "createdAt",
          callback: (value) => {
            return formatter(new Date(value));
          },
        },
      };
    },
    async GET_ALL(response, fromCache) {
      const responsed = await this.$api.get(
        `Quizzes/QuizStatistics?userId=${this.$route.params.id}&page=1&limit=${this.count}&search=${this.search}`
      );
      if (responsed.message === "OK") {
        this.checkedList = responsed.data.items;
        response(responsed.data.items, fromCache);
      }
    },
    toHHMMSS(secs) {
      var sec_num = parseInt(secs, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    },
    courseRateClass(rate) {
      if (rate > 85) return "text-success";
      else if (rate > 70) return "text-info";
      else if (rate > 50) return "text-warning";
      else return "text-danger";
    },
    SET_SEARCH(search) {
      this.search = search;
      this.page = 1;
      this.$router.replace({ query: { search, page: this.page } });
      this.GET_ITEMS();
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { page, search: this.search } });
      this.GET_ITEMS();
    },
    async GET_ITEMS() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Quizzes/QuizStatistics?userId=${this.$route.params.id}&page=${this.page}&limit=${this.limit}&search=${this.search}`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      }
      this.isLoading = false;
    },
    GET_CUSTOMER_TO_LIST() {
      try {
        const id = this.$route.params.id;

        this.$store.dispatch("GET_CUSTOMER_TO_LIST", {
          id: Number(id),
        });
      } catch (error) {
        this.$router.push("/error");
      }
    },
  },
  mounted() {
    this.CREATE_EXCEL_FIELD();
    const q = this.$route.query;
    if (!isNaN(Number(q.page))) this.page = Number(q.page);
    this.GET_ITEMS();
    this.GET_CUSTOMER_TO_LIST();
  },
};
</script>

<style></style>
