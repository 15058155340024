<template>
  <div class="container">
    <h2 class="mt-4">
      {{ $t("joinedQuizzes") }} <span class="text-muted">{{ count }}</span>
    </h2>
    <export-excell
      :checkedList="checkedList"
      :selectedAllItemWithoutPage="selectedAllItemWithoutPage"
      :fields="fields"
      :count="count"
      :name="`${checkedList.length} ${$t('quizzes')}`"
      @getAll="GET_ALL"
    ></export-excell>
    <Table
      checkable
      :check-value="checkedList"
      @change:selectAllWithoutPage="SET_SELECT_ALL_WITHOUT_PAGE"
      @change:checkedList="(e) => (checkedList = e)"
      :count="count"
      no-search
      :loading="loading"
      :items="items"
      :headers="headers"
    >
      <template #quizName="{ item }">
        <p>{{ item.quizName }}</p>
        <div class="text-muted">{{ item.quizStartDate }}</div>
      </template>
      <template #productName="{ item }">
        <p>{{ item.productName }}</p>
        <div class="text-muted">{{ item.videoName }}</div>
      </template>
      <template #questionTotalCount="{ item }">
        <p>
          {{ CALCULATE_PERCENT_OF_CORRECT_QUESTION(item) }}%
          <small class="text-muted"
            >({{ item.correctAnswerTotalCount }} /
            {{ item.questionTotalCount }})</small
          >
        </p>
      </template>
      <template #status="{ item }">
        <router-link
          :to="{
            name: 'QuizDetail',
            params: {
              videoId: `video-${item.videoId}`,
              quizId: item.quizId,
              sectionId: 0,
            },
            query: {
              tab: 'QuizDetailOfUser',
              userId: item.userId || $route.params.id || 2,
            },
          }"
        >
          <button
            :title="$t('getResults')"
            class="btn m-1 btn-pill btn-xs btn-warning mx-1"
          >
            {{ item.id }} <i class="icon-search"></i> {{ $t("getResults") }}
          </button>
        </router-link>
      </template>
    </Table>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
  </div>
</template>

<script>
import Table from "../Tables/index.vue";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import ExportExcell from "../ReportCenter/ExportExcell.vue";
import { formatter } from "gurhan/helpers/Date";
export default {
  components: { Table, BoostrapPagination, ExportExcell },
  props: {
    quiz: {
      default: () => {},
    },
  },
  data() {
    return {
      checkedList: [],
      selectedAllItemWithoutPage: false,
      fields: {},
      count: 0,
      page: 1,
      items: [],
      headers: [
        {
          title: this.$t("quizName"),
          value: "quizName",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("productName"),
          value: "productName",
          col: 6,
          md: 5,
          lg: 4,
          xl: 4,
        },
        {
          title: this.$t("percentOfCorrentAnswer"),
          value: "questionTotalCount",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("situation"),
          value: "status",
          col: 12,
          md: 3,
          lg: 6,
          xl: 6,
        },
      ],
      loading: false,
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  methods: {
    formatter,
    SET_SELECT_ALL_WITHOUT_PAGE(all) {
      this.selectedAllItemWithoutPage = all;
    },
    CREATE_EXCEL_FIELD() {
      this.fields = {
        [`${this.$t("quizName")}`]: {
          field: "quizName",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("productName")}`]: {
          field: "productName",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("questionTotalCount")}`]: {
          field: "questionTotalCount",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("correctAnswerTotalCount")}`]: {
          field: "correctAnswerTotalCount",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("questionTotalCount")}`]: {
          field: "questionTotalCount",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("quizStartDate")}`]: {
          field: "quizStartDate",
          callback: (value) => {
            return this.formatter(value);
          },
        },
        [`${this.$t("quizFinishDate")}`]: {
          field: "quizFinishDate",
          callback: (value) => {
            return this.formatter(value);
          },
        },
      };
    },
    async GET_ALL(response, fromCache) {
      const responsed = await this.$api.get(
        `UserQuizzes/ByCompany?userId=${this.$route.params.id}&page=1&limit=${this.count}`
      );
      if (responsed.message === "OK") {
        this.checkedList = responsed.data.items;
        response(responsed.data.items, fromCache);
      }
    },
    async GET_USER_QUIZ_ITEMS() {
      this.loading = true;
      const response = await this.$api.get(
        `UserQuizzes/ByCompany?userId=${this.$route.params.id}&page=${this.page}&limit=10`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      } else this.items = [];
      this.loading = false;
    },
    GET_PAGE_FROM_QUERY() {
      if (!isNaN(Number(this.$route.query?.page)))
        this.page = Number(this.$route.query?.page);
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.GET_USER_QUIZ_ITEMS();
    },
    CALCULATE_PERCENT_OF_CORRECT_QUESTION(quiz) {
      const total = quiz.correctAnswerTotalCount + quiz.wrongAnswerTotalCount;
      const percentOfCorrectAnswer =
        total == 0
          ? 0
          : Math.ceil((quiz.correctAnswerTotalCount * 100) / total);
      return percentOfCorrectAnswer;
    },
  },
  mounted() {
    this.CREATE_EXCEL_FIELD();
    this.GET_USER_QUIZ_ITEMS();
  },
};
</script>

<style></style>
