var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h2',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("joinedQuizzes"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.count))])]),_c('export-excell',{attrs:{"checkedList":_vm.checkedList,"selectedAllItemWithoutPage":_vm.selectedAllItemWithoutPage,"fields":_vm.fields,"count":_vm.count,"name":`${_vm.checkedList.length} ${_vm.$t('quizzes')}`},on:{"getAll":_vm.GET_ALL}}),_c('Table',{attrs:{"checkable":"","check-value":_vm.checkedList,"count":_vm.count,"no-search":"","loading":_vm.loading,"items":_vm.items,"headers":_vm.headers},on:{"change:selectAllWithoutPage":_vm.SET_SELECT_ALL_WITHOUT_PAGE,"change:checkedList":(e) => (_vm.checkedList = e)},scopedSlots:_vm._u([{key:"quizName",fn:function({ item }){return [_c('p',[_vm._v(_vm._s(item.quizName))]),_c('div',{staticClass:"text-muted"},[_vm._v(_vm._s(item.quizStartDate))])]}},{key:"productName",fn:function({ item }){return [_c('p',[_vm._v(_vm._s(item.productName))]),_c('div',{staticClass:"text-muted"},[_vm._v(_vm._s(item.videoName))])]}},{key:"questionTotalCount",fn:function({ item }){return [_c('p',[_vm._v(" "+_vm._s(_vm.CALCULATE_PERCENT_OF_CORRECT_QUESTION(item))+"% "),_c('small',{staticClass:"text-muted"},[_vm._v("("+_vm._s(item.correctAnswerTotalCount)+" / "+_vm._s(item.questionTotalCount)+")")])])]}},{key:"status",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
          name: 'QuizDetail',
          params: {
            videoId: `video-${item.videoId}`,
            quizId: item.quizId,
            sectionId: 0,
          },
          query: {
            tab: 'QuizDetailOfUser',
            userId: item.userId || _vm.$route.params.id || 2,
          },
        }}},[_c('button',{staticClass:"btn m-1 btn-pill btn-xs btn-warning mx-1",attrs:{"title":_vm.$t('getResults')}},[_vm._v(" "+_vm._s(item.id)+" "),_c('i',{staticClass:"icon-search"}),_vm._v(" "+_vm._s(_vm.$t("getResults"))+" ")])])]}}])}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('BoostrapPagination',{attrs:{"page":_vm.page,"pages":_vm.pages},on:{"change":_vm.SET_PAGINATION}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }