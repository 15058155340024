<template>
  <div>
    <div class="text-right">
      <div class="btn-group-container">
        <button
          @click="detail = true"
          :class="{ subscribe: detail, 'subscribe-light': !detail }"
          class="btn btn-pill"
        >
          {{ $t("showDetail") }}
        </button>
        <button
          @click="detail = false"
          :class="{ subscribe: !detail, 'subscribe-light': detail }"
          class="btn btn-pill"
        >
          {{ $t("listDetail") }}
        </button>
      </div>
    </div>
    <StatisticsByUser v-if="!detail" />
    <QuizzesOfUser v-else />
  </div>
</template>

<script>
import StatisticsByUser from "../components/ReportCenter/QuizStatistics/StatisticsByUser.vue";
import QuizzesOfUser from "../components/User/QuizzesOfUser.vue";
export default {
  components: {
    StatisticsByUser,
    QuizzesOfUser,
  },
  data() {
    return {
      detail: false,
    };
  },
};
</script>

<style></style>
